import React, { useContext, useState } from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import colors from '../themes/main-colors';
import LeftNavMenu from '../components/left-menu-navigation';
import BreadCrumb from '../components/pge-breadcrumb';
import Container from '@material-ui/core/Container';
import {
  renderPageComponents,
  getStatusAlertComponent,
  getHeroComponent,
  getSplitHeaderComponent,
} from '../util/contentful-render-utils';
import './css/contentful-pages.css';
import { Field } from '../contentful.d';
import { LanguageContext } from '../providers/LanguageProvider';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: colors.white,
      width: '100%',
      '& .ContentfulElementColumns2 .spacingComponent': {
        padding: 0,
        [theme.breakpoints.down('xs')]: {
          padding: theme.spacing(1.25, 0),
        },
      },
      '& .ContentfulElementColumns3 .spacingComponent': {
        padding: 0,
        [theme.breakpoints.down('xs')]: {
          padding: theme.spacing(1.25, 0),
        },
      },
    },
    header: {
      '& .hero-container': {
        minHeight: 320,
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(6.25, 5),
          height: 'auto',
          minHeight: 'auto',
        },
      },
      '& .hero-content h1': {
        fontSize: theme.spacing(4.5),
        lineHeight: theme.typography.pxToRem(40),
        fontWeight: 'bold',
        fontFamily: 'Forma-DJR-Display',
        letterSpacing: '0.720px',
        [theme.breakpoints.down('sm')]: {
          fontSize: theme.spacing(3),
          letterSpacing: '0.480px',
          lineHeight: theme.typography.pxToRem(28),
          fontWeight: 700,
        },
      },
      '& .hero-content h1.heading-two': {
        maxWidth: theme.typography.pxToRem(450),
      },
      '& .hero-content p': {
        fontSize: theme.spacing(2.5),
        lineHeight: theme.typography.pxToRem(30),
        maxWidth: theme.typography.pxToRem(450),
        fontFamily: 'Untitled-Sans',
        [theme.breakpoints.down('sm')]: {
          fontSize: theme.typography.pxToRem(16),
        },
      },
      '& .hero-content .button > .MuiButton-root': {
        [theme.breakpoints.down('sm')]: {
          backgroundColor: 'transparent !important',
          border: '2px solid #fff !important',
        },
      },
    },
    heading: {
      fontFamily: 'Forma-DJR-Display',
      fontSize: theme.spacing(4.5),
      color: colors.noirBlur,
      lineHeight: theme.typography.pxToRem(40),
      padding: theme.spacing(0, 0, 2.5, 0),
      fontWeight: 'bold',
      letterSpacing: '0.720px',
      margin: 0,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(3),
        lineHeight: theme.typography.pxToRem(28),
        padding: theme.spacing(0, 0, 1.25, 0),
      },
    },
    description: {
      fontSize: theme.spacing(3),
      color: colors.noirBlur,
      lineHeight: theme.typography.pxToRem(28),
      marginBottom: theme.spacing(2.5),
      fontWeight: 'bold',
      fontFamily: 'Forma-DJR-Display',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(2.5),
        lineHeight: theme.typography.pxToRem(24),
        marginBottom: theme.spacing(1.25),
      },
    },
    menu: {
      backgroundColor: colors.white,
      padding: theme.spacing(2.5, 7.5, 0, 0),
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  }),
);

export const PromotionTemplate = ({ pageContext, data }: any) => {
  const classes = useStyles();
  const { language } = useContext(LanguageContext);
  const [showHeroSignInSection, setShowHeroSignInSection] = useState<boolean>(
    true,
  );

  const page = pageContext[language || 'en'];

  // ** Start temporary rollback of localization for public pages **
  const promotionalPageData = data.allContentfulPagePromotional?.nodes[0];
  // const promotionalPageData =
  //   data.allContentfulPagePromotional?.nodes.find(
  //     (node: any) => node.node_locale === language,
  //   ) || data.allContentfulPagePromotional?.nodes[0];
  // ** End temporary rollback of localization for public pages **

  const TEMPLATE = promotionalPageData?.pageTemplate as Field['Template'];

  if (page?.sectionNavArray && page?.sectionNavArray?.length > 0) {
    page.sectionNavArray[0]['menuOpen'] = true;
  }

  const contentBodyHeaderType =
    promotionalPageData?.contentEntries &&
    promotionalPageData?.contentEntries[0]?.__typename;
  let header;
  const signInShow =
    typeof window !== 'undefined' && window.location.pathname === '/';
  if (
    contentBodyHeaderType === 'ContentfulHeaderSplit' ||
    contentBodyHeaderType === 'ContentfulHeaderhero'
  ) {
    header =
      contentBodyHeaderType === 'ContentfulHeaderhero'
        ? getHeroComponent(
            [promotionalPageData?.contentEntries[0]],
            showHeroSignInSection,
            signInShow,
          )
        : getSplitHeaderComponent(promotionalPageData?.contentEntries[0]);
  }

  return (
    <>
      <Helmet bodyAttributes={{ class: 'TemplatePage' }}>
        <title>
          {promotionalPageData?.browserTitle || promotionalPageData?.pageTitle}
        </title>
        {promotionalPageData?.metaDescriptionSeo && (
          <meta
            name="description"
            content={promotionalPageData.metaDescriptionSeo}
          />
        )}
        {promotionalPageData?.excludeFromGoogleSearch && (
          <meta name="robots" content="noindex" />
        )}
      </Helmet>
      {header && <div className={classes.header}>{header}</div>}
      <Container className="component-container">
        {TEMPLATE === 'Left Nav' &&
          page?.sectionNavArray &&
          page?.sectionNavArray?.length > 0 && (
            <div className={classes.menu}>
              <LeftNavMenu
                leftNavMenuItems={page.sectionNavArray}
                selectedItem={page.slug}
              />
            </div>
          )}
        <div className={classes.root}>
          <BreadCrumb path={page?.breadcrumb} />
          {getStatusAlertComponent(promotionalPageData?.statusAlert)}
          {!header && (
            <div>
              {promotionalPageData?.pageTitle && (
                <h1 className={classes.heading}>
                  {promotionalPageData?.pageTitle}
                </h1>
              )}
              {promotionalPageData?.pageShortDescription && (
                <div className={classes.description}>
                  {promotionalPageData?.pageShortDescription}
                </div>
              )}
            </div>
          )}
          {renderPageComponents({
            entriesData: promotionalPageData?.contentEntries || null,
            pageTemplate: promotionalPageData?.pageTemplate || null,
          })}
        </div>
      </Container>
    </>
  );
};
export default PromotionTemplate;

export const query = graphql`
  query PagePromotion($slug: String!) {
    allContentfulPagePromotional(filter: { slug: { eq: $slug } }) {
      nodes {
        node_locale
        contentful_id
        entryName
        shortMenuTitle
        slug
        pageTitle
        pageShortDescription
        pageTemplate
        browserTitle
        metaDescriptionSeo
        excludeFromGoogleSearch
        excludeFromXmlSiteMap
        contentEntries {
          ...ElementJumpLinks
          ...ModuleContentBody
          ...ModuleNote
          ...ModuleStatusAlert
          ...ModuleCallOut
          ...ModuleColumn2
          ...ModuleColumn3
          ...ModuleContact
          ...ModuleCallToAction
          ...ModuleMessagingBlock
          ...ModulePullQuote
          ...ModuleSplitHeader
          ...ModuleHeroHeader
          ...ModuleAccordion
          ...ModuleContentList
          ...GallerySlider
          ...ModulePromoGroup
          ...ModuleRelated
          ...ModuleTabSet
          ...ModuleTOC
          ...ModuleFormWrapper
          ...ModuleImageWrapper
          ...ModuleEmbedMediaWrapper
          ...ProgramCard
          ...StaticList
          ...CTACard
          ...SocialFeed
        }
        statusAlert {
          ...ModuleStatusAlert
        }
      }
    }
  }
`;
